import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  AccountMenuItem,
  AccountMenuItemProps,
  BadgeProps,
  BuyCSPRMenuItem,
  ClickProvider,
  ClickUI,
  CsprClickThemes,
  Currency,
  CurrencySettings,
  LanguageSettings,
  NetworkSettings,
  ThemeModeType
} from "@make-software/csprclick-ui";
import { CopyHashMenuItem, ViewAccountOnExplorerMenuItem } from '@make-software/csprclick-ui';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { CsprClickInitOptions } from '@make-software/csprclick-core-client';

declare global {
  interface Window {
      csprclickUI: ClickUIApp | null;
  }
}

declare const clickSDKOptions: CsprClickInitOptions;

declare const clickUIOptions: ClickUIAppProps;

interface ClickUIAppProps {
  styledCompContainer?: string,
  uiContainer?: string,
  rootAppElement?: string,
  showTopBar?: boolean,
  show1ClickModal?: boolean,
  accountMenuItems?: Array<string|AccountMenuItemProps>,
  defaultTheme: string,
  onThemeChanged?: (theme: string) => void,
  networkSettings? : NetworkSettings,
  languageSettings? : LanguageSettings,
  currencySettings? : CurrencySettings,
}

interface ClickUIAppState {
  currentTheme: string;
  currentNetwork: string | undefined;
  currentLanguage: string | undefined;
  currentCurrency: Currency | undefined;
}

class ClickUIApp extends React.Component<ClickUIAppProps, ClickUIAppState> {

  constructor(props: ClickUIAppProps) {
    super(props);

    const storedTheme = window.localStorage.getItem("csprclick-theme") || clickUIOptions.defaultTheme || 'light';

    this.state = {
      currentTheme: storedTheme as ThemeModeType,
      currentNetwork: clickUIOptions.networkSettings?.currentNetwork,
      currentLanguage: clickUIOptions.languageSettings?.currentLanguage,
      currentCurrency: clickUIOptions.currencySettings?.currentCurrency,
    };
  }

  toggleTheme = () => {
    const newTheme = this.state.currentTheme === ThemeModeType.dark ? ThemeModeType.light : ThemeModeType.dark;
    window.localStorage.setItem("csprclick-theme", newTheme);
    this.setState(prevState => ({
      ...prevState,
      currentTheme: newTheme,
    }));

    return newTheme;
  }

  handleThemeSwitch = clickUIOptions.onThemeChanged
    ? (() => {
      const newTheme = this.toggleTheme()
      clickUIOptions.onThemeChanged!(newTheme);
    })
    : undefined;

  setTheme = (theme: string) => {
    this.setState(prevState => ({
      ...prevState,
      currentTheme: theme,
    }));
  }

  setNetwork = (network: string) => {
    this.setState(prevState => ({
      ...prevState,
      currentNetwork: network,
    }));
  }

  setLanguage = (language: string) => {
    this.setState(prevState => ({
      ...prevState,
      currentLanguage: language,
    }));
  }

  setCurrency = (currency: Currency) => {
    this.setState(prevState => ({
      ...prevState,
      currentCurrency: currency,
    }));
  }

  render() {
    const accountMenuItems: Array<any> = [];

    clickUIOptions.accountMenuItems?.forEach( item => {
      if(item === "ViewAccountOnExplorerMenuItem")
        accountMenuItems.push(<ViewAccountOnExplorerMenuItem key={"ViewAccountOnExplorerMenuItem"} />)
      if(item === "CopyHashMenuItem")
        accountMenuItems.push(<CopyHashMenuItem key={"CopyHashMenuItem"} />)
      if(item === "BuyCSPRMenuItem")
       accountMenuItems.push(<BuyCSPRMenuItem key={"BuyCSPRMenuItem"} />)
      if(item instanceof Object && item.label && item.onClick) {
        accountMenuItems.push(<AccountMenuItem
          key={item.label}
          label={item.label}
          icon={item.icon}
          onClick={item.onClick}
          badge={item.badge? item.badge as BadgeProps : undefined} />)
      }
    });

    const networkSettings = clickUIOptions.networkSettings
    ? {
      ...clickUIOptions.networkSettings,
      currentNetwork: this.state.currentNetwork,
    } as NetworkSettings
    : undefined;

    const languageSettings = clickUIOptions.languageSettings
    ? {
      ...clickUIOptions.languageSettings,
      currentLanguage: this.state.currentLanguage,
    } as LanguageSettings
    : undefined;

    const currencySettings = clickUIOptions.currencySettings
    ? {
      ...clickUIOptions.currencySettings,
      currentCurrency: this.state.currentCurrency,
    } as CurrencySettings
    : undefined;

    const topBarSettings = (clickUIOptions.showTopBar || this.handleThemeSwitch || accountMenuItems.length > 0 || networkSettings || languageSettings || currencySettings)
    ? {
      onThemeSwitch: this.handleThemeSwitch,
      accountMenuItems: accountMenuItems,
      networkSettings: networkSettings,
      languageSettings: languageSettings,
      currencySettings: currencySettings,
    }
    : undefined;

    const themeMode = this.state.currentTheme as ThemeModeType;

    return (<ThemeProvider theme={CsprClickThemes[themeMode]}>
              <div className="csprclick-ui">
                        <ClickUI
                          themeMode={themeMode}
                          topBarSettings={topBarSettings}
                          rootAppElement={clickUIOptions.rootAppElement || '#root'}
                          show1ClickModal={clickUIOptions.show1ClickModal ?? true}
                          />
              </div>
      </ThemeProvider>)
  }
}

let styledCompContainer = undefined;
if(clickUIOptions.styledCompContainer)
  styledCompContainer = document.getElementById(clickUIOptions.styledCompContainer) || undefined;

const root = ReactDOM.createRoot(
  document.getElementById(clickUIOptions.uiContainer || 'csprclick-navbar') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ClickProvider options={clickSDKOptions}>
        <StyleSheetManager target={styledCompContainer}>
          <ClickUIApp
            {...clickUIOptions}
            ref={ClickUI => { window.csprclickUI = ClickUI }}
          />
      </StyleSheetManager>
    </ClickProvider>
  </React.StrictMode>
);
